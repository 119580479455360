import React, { useState } from "react";
import { DatePicker, Input, Select, SelectItem } from "@nextui-org/react";
import { now, getLocalTimeZone } from "@internationalized/date";

export default function Accommodation() {
  const [city, setCity] = useState("");
  const [checkInDate, setCheckInDate] = useState(now(getLocalTimeZone()));
  const [checkOutDate, setCheckOutDate] = useState(now(getLocalTimeZone()));
  const [roomType, setRoomType] = useState("");
  const [price, setPrice] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = {
      city,
      checkInDate,
      checkOutDate,
      roomType,
      price,
    };
    console.log("Form Data:", formData);
    // Add form submission logic here (e.g., send data to a server)
  };

  return (
    <form className="w-full max-w-2xl" onSubmit={handleSubmit} id="flight-form">
      <div>
        <h2>Enter your flight details below.</h2>
      </div>
      <div className="grid gap-6 mt-5">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Input
              id="departure-airport"
              placeholder="Enter departure airport"
              label={"City"}
              labelPlacement="outside"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Select
              id="room"
              label={"Room"}
              labelPlacement="outside"
              placeholder="Select an airline"
              value={roomType}
              onChange={(e) => setRoomType(e.target.value)}
            >
              <SelectItem value="aa" key={"aa"}>
                Double Room
              </SelectItem>
              <SelectItem value="ua" key={"ua"}>
                Suite
              </SelectItem>
            </Select>
          </div>
        </div>
        <div className="flex">
          <div className="w-[100%]">
            <DatePicker
              label={"Check In"}
              granularity="second"
              className="w-[100%]"
              description={"Check In"}
              labelPlacement={"outside"}
              hideTimeZone
              showMonthAndYearPickers
              value={checkInDate}
              onChange={setCheckInDate}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-[100%]">
            <DatePicker
              label={"Check Out"}
              granularity="second"
              className="w-[100%]"
              description={"Check Out"}
              labelPlacement={"outside"}
              hideTimeZone
              showMonthAndYearPickers
              value={checkOutDate}
              onChange={setCheckOutDate}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4"></div>
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="number"
            label="Price"
            placeholder="0.00"
            labelPlacement="outside"
            startContent={
              <div className="pointer-events-none flex items-center">
                <span className="text-default-400 text-small">$</span>
              </div>
            }
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
}
