// utils/dateUtils.ts
import dayjs from "dayjs";

export function getStartAndEndDates(dateMap: { [key: string]: any }) {
  const dates = Object.keys(dateMap);
  const startDate = dates.reduce(
    (min, date) => (dayjs(date).isBefore(dayjs(min)) ? date : min),
    dates[0]
  );
  const endDate = dates.reduce(
    (max, date) => (dayjs(date).isAfter(dayjs(max)) ? date : max),
    dates[0]
  );

  return { startDate, endDate };
}
