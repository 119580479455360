let environment: Environment;

if (process.env.NODE_ENV === "production") {
  environment = require("./environment.prod").environment as Environment;
} else {
  environment = require("./environment.dev").environment as Environment;
}

export interface Environment {
  production: boolean;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  collections: {
    users: string;
    byotSearchHistory: string;
    planned_activities: string;
    planned_flights: string;
    planned_hotels: string;
    trips: string;
  };
  stripePublicKey: string;
}

export { environment };
