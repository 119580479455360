import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { environment } from "../environments/environment";
import { CircularProgress } from "@nextui-org/react";
import {
  getPlannedActivitiesByIds,
  getPlannedFlightsByIds,
  getPlannedHotelsByIds,
  subscribeToDocumentById,
  subscribeToNestedDocuments,
} from "../services/firebaseRetrieveService";
import TripSchedule from "../components/TripSchedule";
import { UserContext } from "../providers/UserContext";
import ViewTrip from "../components/ViewTrip";

const env = environment.collections;

const fetchTripData = async (tripData: any) => {
  const [plannedActivities, plannedFlights, plannedHotels] = await Promise.all([
    getPlannedActivitiesByIds(tripData.tripObjects.plannedActivitiesIds),
    getPlannedFlightsByIds(tripData.tripObjects.plannedFlightsIds),
    getPlannedHotelsByIds(tripData.tripObjects.plannedHotelsIds),
  ]);

  return {
    id: tripData.id,
    ...tripData,
    tripObjects: {
      plannedActivities,
      plannedFlights,
      plannedHotels,
    },
  };
};

const processTripsData = (trips: any) => {
  const dateMap: any = {};
  const activitiesSet = new Set();
  const flightsSet = new Set();
  const hotelsSet = new Set();

  const formatDate = (date: any) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  trips.forEach((trip: any) => {
    trip.tripObjects.plannedActivities.forEach((activity: any) => {
      const date = formatDate(activity.activityDetails.activityDate);
      if (!dateMap[date])
        dateMap[date] = { activities: [], flights: [], hotels: [] };
      dateMap[date].activities.push(activity);
      activitiesSet.add(activity);
    });

    trip.tripObjects.plannedFlights.forEach((flight: any) => {
      const departureDate = formatDate(flight.flightDetails.departureDate);
      if (!dateMap[departureDate])
        dateMap[departureDate] = { activities: [], flights: [], hotels: [] };
      dateMap[departureDate].flights.push(flight);

      const arrivalDate = formatDate(flight.flightDetails.arrivalDate);
      if (departureDate !== arrivalDate) {
        if (!dateMap[arrivalDate])
          dateMap[arrivalDate] = { activities: [], flights: [], hotels: [] };
        dateMap[arrivalDate].flights.push(flight);
      }
      flightsSet.add(flight);
    });

    trip.tripObjects.plannedHotels.forEach((hotel: any) => {
      const checkInDate = formatDate(hotel.hotelDetails.checkInDate);
      const checkOutDate = formatDate(hotel.hotelDetails.checkOutDate);

      let currentDate = new Date(checkInDate);
      const endDate = new Date(checkOutDate);

      while (currentDate <= endDate) {
        const dateStr = formatDate(currentDate);
        if (!dateMap[dateStr])
          dateMap[dateStr] = { activities: [], flights: [], hotels: [] };
        dateMap[dateStr].hotels.push(hotel);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      hotelsSet.add(hotel);
    });
  });

  const activities = Array.from(activitiesSet);
  const flights = Array.from(flightsSet);
  const hotels = Array.from(hotelsSet);

  return { dateMap, activities, flights, hotels };
};

export const Trip = () => {
  let { tripID } = useParams();
  const [dateMap, setDateMap] = useState({});
  const [trip, setTrip] = useState({} as any);
  const [activities, setActivities] = useState([] as any);
  const [flights, setFlights] = useState([] as any);
  const [hotels, setHotels] = useState([] as any);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  let isOwnTrip = false;

  useEffect(() => {
    const handleSubDocUpdate = (tripData: any) => {
      setTripData(tripData);
    };

    const setTripData = async (tripData: any) => {
      const updatedTrip = await fetchTripData(tripData);
      const { dateMap, activities, flights, hotels } = processTripsData([
        updatedTrip,
      ]);
      setActivities(activities);
      setFlights(flights);
      setHotels(hotels);
      console.log("Processed Trips", dateMap);
      setDateMap(dateMap);
      setTrip(tripData);
      setLoading(false);
    };

    const unsubscribeMainDoc = subscribeToDocumentById(
      env.trips,
      tripID,
      async (trip: any) => {
        if (trip) {
          setLoading(true);
          const unsubscribeNestedDoc = subscribeToNestedDocuments(
            trip,
            handleSubDocUpdate
          );
          return () => {
            unsubscribeNestedDoc();
          };
        } else {
          setTrip(null);
          setLoading(false);
        }
      }
    );

    return () => {
      unsubscribeMainDoc();
    };
  }, [tripID]);
  console.log(user, trip);
  if (user && trip && trip.ownerId === user.id) {
    isOwnTrip = true;
  }
  return (
    <div className="w-screen h-screen">
      {loading ? (
        <div className="w-screen h-screen flex justify-center items-center">
          <CircularProgress size="lg"></CircularProgress>
        </div>
      ) : (
        <>
          <ViewTrip
            trip={trip}
            dateMap={dateMap}
            activities={activities}
            flights={flights}
            hotels={hotels}
          ></ViewTrip>
          <h3 className="text-2xl font-bold text-center my-4">
            Your trip, day by day
          </h3>
          <TripSchedule dateMap={dateMap} isOwnTrip={isOwnTrip} />
        </>
      )}
    </div>
  );
};
