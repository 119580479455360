import React from "react";
import dayjs from "dayjs";
import EmptyDate from "./EmptyDate";
import { Accordion, AccordionItem } from "@nextui-org/react";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import FlightIcon from "@mui/icons-material/Flight";
import BedIcon from "@mui/icons-material/Bed";
import { getStartAndEndDates } from "../utils/dateUtils";

export default function TripSchedule({ dateMap, isOwnTrip }) {
  const { startDate, endDate } = getStartAndEndDates(dateMap);

  let currentDate = dayjs(startDate);
  const endDateDayjs = dayjs(endDate);

  while (currentDate <= endDateDayjs) {
    const dateString = currentDate.format("YYYY-MM-DD");
    if (!dateMap[dateString]) {
      dateMap[dateString] = { activities: [], flights: [], hotels: [] };
    }
    currentDate = currentDate.add(1, "day");
  }

  const dateEntries = Object.entries(dateMap).sort((a, b) =>
    dayjs(a[0]).isBefore(dayjs(b[0])) ? -1 : 1
  );

  return (
    <div className="container mx-auto lg:p-4">
      {dateEntries.map(([date, details]) => (
        <DayPlan
          key={date}
          date={date}
          details={details}
          isOwnTrip={isOwnTrip}
        />
      ))}
    </div>
  );
}

const DayPlan = ({ date, details, isOwnTrip }) => {
  const itemClasses = {
    base: "py-0 w-full",
    title: "text-xl font-semibold",
    indicator: "text-xl font-semibold",
  };
  let defaultExpandedKeys = [];
  if (
    details.activities.length ||
    details.flights.length ||
    details.hotels.length
  ) {
    defaultExpandedKeys = [date];
  }
  return (
    <Accordion
      defaultExpandedKeys={defaultExpandedKeys}
      variant="shadow"
      className="mb-2 lg:px-4 px-2"
      itemClasses={itemClasses}
    >
      <AccordionItem
        key={date}
        aria-label={date}
        title={new Date(date).toDateString().slice(4)}
      >
        <div className="bg-white rounded-lg mb-2">
          <div className="flex align-center gap-2">
            <div className="bg-secondary p-5 text-white w-1 text-center flex items-center justify-center">
              <h3 className="text-xl font-semibold">
                <LocalActivityIcon />
              </h3>
            </div>
            <div className="flex-1">
              {details.activities && (
                <h4 className="text-xl font-semibold">Activities</h4>
              )}
              {details.activities.length ? (
                details.activities.map((activity) => (
                  <div
                    key={activity.id}
                    className="border-b border-gray-200 pt-2 mt-2"
                  >
                    <p>
                      <strong>Type:</strong>{" "}
                      {activity.activityDetails.activityType}
                    </p>
                    <p>
                      <strong>Destination:</strong>{" "}
                      {activity.activityDetails.destination}
                    </p>
                    <p>
                      <strong>Notes:</strong> {activity.activityDetails.notes}
                    </p>
                    <p>
                      <strong>Comments:</strong>{" "}
                      {activity.activityDetails.userComment}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  <EmptyDate type="Activities" isOwnTrip={isOwnTrip} />
                  <div className="border-t border-gray-200 pt-2 mt-2"></div>
                </>
              )}
            </div>
          </div>

          <div className="flex align-center gap-2">
            <div className="bg-accent p-5 text-white w-1 text-center flex items-center justify-center">
              <h3 className="text-xl font-semibold">
                <FlightIcon />
              </h3>
            </div>
            <div className="flex-1">
              {details.flights && (
                <h4 className="text-xl font-semibold">Flights</h4>
              )}
              {details.flights.length ? (
                details.flights.map((flight) => (
                  <div
                    key={flight.id}
                    className=" border-b border-gray-200pt-2 mt-2"
                  >
                    <p>
                      <strong>Airline:</strong> {flight.flightDetails.airline}
                    </p>
                    <p>
                      <strong>Flight Number:</strong>{" "}
                      {flight.flightDetails.flightNumber}
                    </p>
                    <p>
                      <strong>From:</strong>{" "}
                      {flight.flightDetails.departureAirport}{" "}
                      <strong>To:</strong> {flight.flightDetails.arrivalAirport}
                    </p>
                    <p>
                      <strong>Departure:</strong>{" "}
                      {flight.flightDetails.departureDate} at{" "}
                      {flight.flightDetails.departureTime}
                    </p>
                    <p>
                      <strong>Arrival:</strong>{" "}
                      {flight.flightDetails.arrivalDate} at{" "}
                      {flight.flightDetails.arrivalTime}
                    </p>
                    <p>
                      <strong>Comments:</strong>{" "}
                      {flight.flightDetails.userComments}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  <EmptyDate type="Flights" isOwnTrip={isOwnTrip} />
                  <div className="border-t border-gray-200 pt-2 mt-2"></div>
                </>
              )}
            </div>
          </div>

          <div className="flex align-center gap-2">
            <div className="bg-primary p-5 text-white w-1 text-center flex items-center justify-center">
              <h3 className="text-xl font-semibold">
                <BedIcon />
              </h3>
            </div>
            <div className="flex-1">
              {details.hotels && (
                <h4 className="text-xl font-semibold">Hotels</h4>
              )}
              {details.hotels.length ? (
                details.hotels.map((hotel) => (
                  <div
                    key={hotel.id}
                    className="border-b border-gray-200 pt-2 mt-2 last:border-b-0"
                  >
                    <p>
                      <strong>City:</strong> {hotel.hotelDetails.city}
                    </p>
                    <p>
                      <strong>Room Type:</strong> {hotel.hotelDetails.roomType}
                    </p>
                    <p>
                      <strong>Check-in:</strong>{" "}
                      {hotel.hotelDetails.checkInDate}
                    </p>
                    <p>
                      <strong>Check-out:</strong>{" "}
                      {hotel.hotelDetails.checkOutDate}
                    </p>
                    <p>
                      <strong>Notes:</strong> {hotel.hotelDetails.notes}
                    </p>
                    <p>
                      <strong>Comments:</strong>{" "}
                      {hotel.hotelDetails.userComment}
                    </p>
                    <div>
                      <strong>Preferred Hotels:</strong>
                      <ul>
                        {hotel.hotelDetails.preferredHotels.map((ph, index) => (
                          <li key={index}>
                            {ph.name} - {ph.userReason}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <EmptyDate type="Hotels" isOwnTrip={isOwnTrip} />
                </>
              )}
            </div>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};
