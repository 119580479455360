import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import PlaceIcon from "@mui/icons-material/Place";
import React, { useEffect, useState } from "react";
import { getCitiesFromDatabase } from "../controller/locations_database_controller";
import {
  City,
  LocationsDatabaseHTTPRequest,
} from "../interfaces/locations_database_interfaces";
import { debounce } from "lodash";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchDestinations() {
  const [query, setQuery] = useState("");
  const [selectedDestinations, setSelectedDestination] = useState<City[]>([]);
  const [destinations, setDestinations] = useState<City[] | null>(null);

  const fetchCities = debounce(async () => {
    const queryOptions: LocationsDatabaseHTTPRequest = {
      cityName: query,
      limit: 10,
    };
    const response = await getCitiesFromDatabase(queryOptions);
    if (!response.success) {
      console.error(response.errorMessage);
      setDestinations([]);
      return;
    }
    const cities = response.cities;
    setDestinations(cities);
  }, 1000); // delay in milliseconds

  useEffect(() => {
    if (query !== "") {
      fetchCities();
    }
  }, [fetchCities, query]);

  return (
    <Combobox
      multiple
      value={selectedDestinations}
      onChange={(destination: any) => {
        console.log(destination);
        setQuery("");
        setSelectedDestination(destination);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className="w-full rounded-md h-14 sm:text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(destination: any) => destination?.name}
          placeholder="Search destinations"
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className=" w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>
        {destinations && destinations.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {destinations.map((destination: any) => (
              <ComboboxOption
                key={destination.id}
                value={destination}
                className={({ focus }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    focus ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <div className="flex items-center">
                      <PlaceIcon className="h-6 w-6 flex-shrink-0 text-cartago-blue" />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {destination.name}
                        {", "}
                        {destination.country_code
                          ? `(${destination.country_code})`
                          : ""}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          focus ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
      {selectedDestinations.length > 0 &&
        selectedDestinations.map((destination: any) => (
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {destination.name}
          </span>
        ))}
    </Combobox>
  );
}
