import React from "react";
import TripCard from "./TripCard";


export const Feed = () => {

    const cardsInfo = [
        {
            title: "Card 1",
            description: "This is the first card",
            imageUrl: "https://via.placeholder.com/400x200",
        },
        {
            title: "Card 2",
            description: "This is the second card",
            imageUrl: "https://via.placeholder.com/400x200",
        },
        {
            title: "Card 3",
            description: "This is the third card",
            imageUrl: "https://via.placeholder.com/400x200",
        },
    ];

  return (
    <div className="w-full h-full sm:block md:flex">
        <TripCard />
        <TripCard />
        <TripCard />
        <TripCard />
        <TripCard />
    </div>
  );
};

export default Feed;
