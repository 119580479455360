import { Share } from "@capacitor/share";

export const share = async (message: string, url?: string) => {
  await Share.share({
    title: "Cartago",
    text: message,
    url: url || "https://cartago.travel",
    dialogTitle: "Take a look at this trip!",
  });
};
