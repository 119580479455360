import { Button, Card, CardBody, Divider, CardHeader } from "@nextui-org/react";
import Calendar from "./CalendarMonthView";

export default function Checkout() {
  return (
    <div className="px-4 py-8 md:py-12 lg:py-16 xl:py-20 2xl:py-24">
      <div className="mx-auto max-w-6xl grid gap-4 lg:gap-8">
        <div className="grid gap-2">
          <h1 className="text-3xl font-semibold tracking-tight lg:text-4xl xl:text-5xl">
            Trip summary
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Review your trip details and confirm your booking.
          </p>
        </div>
        <Card>
          <CardBody className="p-4 md:p-6 grid gap-4">
            <div className="grid gap-2">
              <h2 className="text-xl font-semibold">Flight</h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                You're flying with Delta
              </p>
            </div>
            <div className="grid gap-2">
              <h3 className="font-semibold">JFK to LAX</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Depart: 10:00 AM, 12th August 2024
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Arrive: 1:00 PM, 12th August 2024
              </p>
            </div>
            <Divider />
            <div className="grid gap-2">
              <h2 className="text-xl font-semibold">Hotel</h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                You're staying at the Hilton
              </p>
            </div>
            <div className="grid gap-2">
              <h3 className="font-semibold">Hilton Los Angeles</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Check-in: 3:00 PM, 12th August 2024
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Check-out: 12:00 PM, 15th August 2024
              </p>
            </div>
            <Divider />
            <div className="grid gap-2">
              <h2 className="text-xl font-semibold">Passenger information</h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Enter the names of the passengers as they appear on their
                government-issued photo ID or passport.
              </p>
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <span className="text-sm">Name</span>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  John Doe
                </p>
              </div>
              <div className="grid gap-2">
                <span className="text-sm">Email</span>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  john.doe@example.com
                </p>
              </div>
            </div>
            <Divider className="my-6" />
            <div className="grid gap-2">
              <h2 className="text-xl font-semibold">Payment information</h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Enter your payment information to confirm your booking.
              </p>
            </div>
            <div className="grid gap-4">
              <Card>
                <CardHeader>
                  <h2>Billing information</h2>
                </CardHeader>
                <CardBody>
                  <div className="grid gap-2 md:grid-cols-2">
                    <div>
                      <div className="font-medium">Sophia Anderson</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        Credit card ending in 4242
                      </div>
                    </div>
                    <div className="text-right">
                      <Button size="sm" className="bg-cartago-blue text-white">
                        Use a different card
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
        <Button className="mx-auto justify-center w-full md:w-auto bg-cartago-blue text-white">
          Confirm Booking
        </Button>
      </div>
      <Calendar />
    </div>
  );
}
