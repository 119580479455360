import { PlusIcon } from "@heroicons/react/20/solid";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import Form from "./forms/Form";

export default function EmptyDate({ type, isOwnTrip }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className="text-center flex-1">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No {type}</h3>
      {isOwnTrip ? (
        <p className="mt-1 text-sm text-gray-500">
          Get started by adding a new {type}.
        </p>
      ) : (
        <p className="mt-1 text-sm text-gray-500">
          This day plan has no {type.toLowerCase()} yet.
        </p>
      )}
      {isOwnTrip && (
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onOpen}
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New {type}
          </button>
        </div>
      )}
      <div>
        <Modal
          isOpen={isOpen}
          placement={"bottom-center"}
          onOpenChange={onOpenChange}
          title="NOT MODAL TITLE"
        >
          <ModalContent className="min-h-[90vh]">
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  Enter {type.toLowerCase()} information
                </ModalHeader>
                <ModalBody>
                  <Form formType={type.toLowerCase()} />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                  <Button
                    color="primary"
                    onPress={onClose}
                    type="submit"
                    form="flight-form"
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
}
