import React from 'react';
import tempImg from '../assets/image.png';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const TripCard = () => {
  return (
    <div className="max-w-sm rounded-lg overflow-hidden shadow-lg bg-white m-4 relative">
      <img className="w-full" src={tempImg} alt="Imagen de ejemplo" />
      <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs px-2 py-1 rounded-full">Featured</div>
      <button className="absolute top-2 right-2 bg-white p-1 rounded-full shadow">
        <FavoriteBorderIcon sx={{ color: '#215273' }}/>
      </button>
      <div className="px-6 py-4">
        <div className="font-bold text-sm text-gray-500 mb-1">WELLNESS</div>
        <div className="font-bold text-xl mb-2">Bali Comfort</div>
        <p className="text-gray-700 text-base">
          9-day trip around Indonesian most famous islands
        </p>
        <p className="text-gray-500 text-sm mt-2">May/2023</p>
        <p className="text-gray-700 text-sm mt-1">From <span className="text-xl font-bold text-cartago-blue">$3,200</span></p>
      </div>
      <div className="px-6 py-4">
        <button className="w-full border-medium border-cartago-blue text-cartago-blue hover:bg-cartago-blue hover:text-white font-bold py-2 px-4 rounded-full">
          View trip
        </button>
      </div>
    </div>
  );
};

export default TripCard;
