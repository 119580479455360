import React, { useContext } from "react";
import { auth, db, googleProvider } from "../firebase";
import { signInWithPopup, getAdditionalUserInfo, User } from "firebase/auth";
import googleLogo from "../assets/google-icon.webp";
import { setDoc, doc } from "firebase/firestore";
import { environment } from "../environments/environment";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../providers/UserContext";

const GoogleLogin = () => {
  const navigate = useNavigate();
  const { setUserToken } = useContext(UserContext);

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken(); // getIdToken is asynchronous, so you need to await it
      const additionalUserInfo = getAdditionalUserInfo(result);
      const isNewUser = additionalUserInfo?.isNewUser;
      const user = result.user;
      console.log("User is logged in", user);
      if (isNewUser) {
        console.log("Creating new user");
        createUser(user);
      }
      setUserToken(token);

      navigate("/home");
    } catch (err) {
      console.error("Error logging in with Google: ", err);
    }
  };

  return (
    <div className="flex items-center px-2 py-5 w-100 justify-center mt-5">
      <button onClick={handleGoogleLogin} className="w-10">
        <img src={googleLogo} alt="Google Sign In" />
      </button>
    </div>
  );
};

export default GoogleLogin;

const createUser = async (user: User) => {
  // Add user info to Firestore
  await setDoc(doc(db, environment.collections.users, user.uid), {
    uid: user.uid,
    email: user.email,
    createdAt: new Date(),
    profilePicture: user.photoURL,
    // Add more fields as needed
  });
};
