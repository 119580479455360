import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { environment } from "../environments/environment";

const env = environment.collections;

const getTripsForUser = async (userId) => {
  const trips = [];
  const tripsCollection = collection(db, env.trips);
  const q = query(tripsCollection, where("ownerId", "==", userId));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      trips.push({ id: doc.id, ...doc.data() });
    });
    return trips;
  } catch (error) {
    console.error("Error retrieving trips: ", error);
    return [];
  }
};

const getDocumentById = async (collectionName, docId) => {
  const docRef = doc(db, collectionName, docId);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log(`No such document in ${collectionName} with id ${docId}`);
      return null;
    }
  } catch (error) {
    console.error(`Error retrieving document from ${collectionName}: `, error);
    return null;
  }
};

const subscribeToDocumentById = (collectionName, docId, callback) => {
  const docRef = doc(db, collectionName, docId);
  const unsubscribe = onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        callback({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log(`No such document in ${collectionName} with id ${docId}`);
        callback(null);
      }
    },
    (error) => {
      console.error(
        `Error retrieving document from ${collectionName}: `,
        error
      );
      callback(null);
    }
  );

  return unsubscribe;
};

const subscribeToNestedDocuments = (mainDoc, callback) => {
  const unsubscribes = [];
  const tripObjects = mainDoc.tripObjects;

  const subscribeToSubDocs = (ids, collectionName, setSubDocs) => {
    const subUnsubscribes = ids.map((id) => {
      return subscribeToDocumentById(collectionName, id, (subDoc) => {
        setSubDocs((prevSubDocs) => ({
          ...prevSubDocs,
          [id]: subDoc,
        }));
      });
    });
    unsubscribes.push(...subUnsubscribes);
  };

  // Subscribe to planned activities
  if (
    tripObjects.plannedActivitiesIds &&
    tripObjects.plannedActivitiesIds.length > 0
  ) {
    const activities = {};
    subscribeToSubDocs(
      tripObjects.plannedActivitiesIds,
      env.planned_activities,
      (subDocs) => {
        Object.assign(activities, subDocs);
        mainDoc.plannedActivitiesData = activities;
        callback(mainDoc);
      }
    );
  }

  // Subscribe to planned hotels
  if (tripObjects.plannedHotelsIds && tripObjects.plannedHotelsIds.length > 0) {
    const hotels = {};
    subscribeToSubDocs(
      tripObjects.plannedHotelsIds,
      env.planned_hotels,
      (subDocs) => {
        Object.assign(hotels, subDocs);
        mainDoc.plannedHotelsData = hotels;
        callback(mainDoc);
      }
    );
  }

  // Subscribe to planned flights
  if (
    tripObjects.plannedFlightsIds &&
    tripObjects.plannedFlightsIds.length > 0
  ) {
    const flights = {};
    subscribeToSubDocs(
      tripObjects.plannedFlightsIds,
      env.planned_flights,
      (subDocs) => {
        Object.assign(flights, subDocs);
        mainDoc.plannedFlightsData = flights;
        callback(mainDoc);
      }
    );
  }

  return () => {
    unsubscribes.forEach((unsubscribe) => unsubscribe());
  };
};

const getPlannedActivitiesByIds = async (activityIds) => {
  if (!activityIds) return [];
  const activities = await Promise.all(
    activityIds.map((id) => getDocumentById(env.planned_activities, id))
  );
  return activities.filter((activity) => activity !== null);
};

const getPlannedFlightsByIds = async (flightIds) => {
  if (!flightIds) return [];
  const flights = await Promise.all(
    flightIds.map((id) => getDocumentById(env.planned_flights, id))
  );
  return flights.filter((flight) => flight !== null);
};

const getPlannedHotelsByIds = async (hotelIds) => {
  if (!hotelIds) return [];
  const hotels = await Promise.all(
    hotelIds.map((id) => getDocumentById(env.planned_hotels, id))
  );
  return hotels.filter((hotel) => hotel !== null);
};

export {
  getTripsForUser,
  getPlannedActivitiesByIds,
  getPlannedFlightsByIds,
  getPlannedHotelsByIds,
  getDocumentById,
  subscribeToDocumentById,
  subscribeToNestedDocuments,
};
