import React from "react";
import { Card, CardBody, CardFooter, Button, Chip } from "@nextui-org/react";
import { HeartIcon, ShareIcon } from "lucide-react";
import { getStartAndEndDates } from "../utils/dateUtils";
import { share } from "../utils/capacitor";

export function formatPrice(amount: number, currency: string): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount);
}

export default function ViewTrip({
  trip,
  dateMap,
  activities,
  flights,
  hotels,
}: any) {
  console.log(dateMap, activities, flights, hotels);
  const nights = Object.keys(dateMap).length;
  const days = nights - 1;
  const totalAmount = trip.totalAmount;
  const currency = trip.currency;
  let { startDate, endDate } = getStartAndEndDates(dateMap);
  const images = trip.images || [];
  startDate = new Date(startDate).toDateString().slice(4);
  endDate = new Date(endDate).toDateString().slice(4);

  return (
    <div className="flex flex-col">
      {/* Main Content */}
      <main className="flex-grow overflow-y-auto">
        <Card className="w-full rounded-none shadow-none">
          <CardBody className="p-0">
            <div className="relative h-72 md:h-96">
              <img
                src={images[0]}
                alt="Italy"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-4 left-4">
                <Button
                  isIconOnly
                  variant="light"
                  className="bg-white/20 backdrop-blur-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </Button>
              </div>
              <div className="absolute top-4 right-4 flex space-x-2">
                <Button
                  isIconOnly
                  variant="light"
                  className="bg-white/20 backdrop-blur-lg"
                >
                  <HeartIcon className="w-6 h-6" />
                </Button>
                <Button
                  isIconOnly
                  variant="light"
                  className="bg-white/20 backdrop-blur-lg"
                >
                  <ShareIcon
                    className="w-6 h-6"
                    onClick={() =>
                      share(
                        "Trip to Italy!",
                        "http://localhost:3000/trip/nhWnsvw52TsfDQYSRlf6"
                      )
                    }
                  />
                </Button>
              </div>
              <div className="absolute bottom-4 left-4 text-white">
                <p className="text-sm font-semibold">
                  {nights} nights / {days} days
                </p>
                <h1 className="text-3xl font-bold mt-2">Italy Starter Kit</h1>
                <p className="text-sm mt-2">
                  Roam through the land of the ancient philosophers and enjoy
                  the Mediterranean Sea
                </p>
              </div>
            </div>

            <div className="p-4">
              <h2 className="text-xl font-semibold text-[#215273]">
                Immerse yourself in "La Dolce Vita" and kick-start your summer
              </h2>
              <div className="flex items-center mt-2">
                <span className="text-2xl font-bold mr-2">4.5</span>
                {[1, 2, 3, 4].map((_, index) => (
                  <svg
                    key={index}
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
                <svg
                  className="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
              <div className="flex flex-wrap gap-2 mt-4">
                {[
                  "Relax",
                  "Beach",
                  "Culture",
                  "Art",
                  "Adventuring",
                  "Foodie",
                  "Museums",
                ].map((tag, index) => (
                  <Chip key={index} color="primary" variant="flat">
                    {tag}
                  </Chip>
                ))}
              </div>
              <p className="mt-4 text-gray-600">
                Discover paradise in Italy, where crystal-clear waters meet
                ancient ruins and charming villages. Wander through whitewashed
                streets, savor fresh seafood, and soak up the sun on stunning
                beaches. Each island offers unique beauty and rich history for
                an unforgettable escape.
              </p>
              <Card className="mt-6">
                <CardBody>
                  <h3 className="text-lg font-semibold text-[#016670]">
                    What's included?
                  </h3>
                  <ul className="list-disc list-inside mt-2 text-gray-600">
                    <li>5 nights in the structure, with breakfast</li>
                    {/* Add more items as needed */}
                  </ul>
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
      </main>

      {/* Sticky Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-md">
        <div className="max-w-screen-xl mx-auto px-4 py-3 md:py-4 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-2 md:mb-0">
            <p className="text-2xl font-bold text-center">
              {formatPrice(totalAmount, currency)}
            </p>
            <p className="text-sm text-gray-500">
              {startDate} - {endDate} ({nights} nights)
            </p>
          </div>
          <div className="flex w-full md:w-auto space-x-2">
            <Button
              color="primary"
              variant="flat"
              className="flex-1 md:flex-none"
            >
              Customize
            </Button>
            <Button color="primary" className="flex-1 md:flex-none">
              Book Now
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
}
