import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { User } from "../interfaces/user";
import { environment } from "../environments/environment";
interface UserContextType {
  user: User | null;
  setUser: (user: User) => void;
  userToken: string | null;
  setUserToken: (token: string) => void;
}

// Create the context with the default value
export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  userToken: null,
  setUserToken: () => {},
});

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null); // Add this line
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        const userRef = doc(db, environment.collections.users, user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserToken(await user.getIdToken());
          setUser(userSnap.data() as User);
          console.log("User is logged in", userSnap.data());
          unsubscribe();
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <UserContext.Provider value={{ user, setUser, userToken, setUserToken }}>
      {children}
    </UserContext.Provider>
  );
};
