import FlightForm from "./FlightForm";
import Accommodation from "./Accommodation";

export default function Form({
  formType,
}: {
  formType: string;
  setForm: (form: any) => void;
}) {
  switch (formType) {
    case "flights":
      return <FlightForm />;
    case "hotels":
      return <Accommodation />;
    default:
      return <div>Form not found</div>;
  }
}
