import React from "react";
import backgroundImg from "../assets/image.png";
import { Select, SelectItem } from "@nextui-org/select";
import { Button, DateRangePicker } from "@nextui-org/react";
import SearchDestinations from "./SearchDestinations";
import SearchIcon from "@mui/icons-material/Search";

export const Filters = () => {
  return (
    <div
      className="w-full h-full py-24 px-4 md:px-72 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <div className="flex flex-col items-start gap-2">
        <h1 className="text-white font-bold text-2xl">
          Explore the world with us
        </h1>
        <div className="flex flex-col md:flex-row w-full gap-5">
          <div className="w-full md:max-w-xs">
            <SearchDestinations />
          </div>
          <div className="w-full md:flex-nowrap gap-4">
            <DateRangePicker label="When" visibleMonths={2} />
          </div>
          <Select label="Who?" className="w-full md:max-w-xs">
            <SelectItem key="Item1" value="Trip1"></SelectItem>
          </Select>
          <Button
            radius="full"
            style={{ backgroundColor: "#215273" }}
            aria-label="Search"
          >
            <SearchIcon sx={{ color: "white" }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
