import React from "react";
import Header from "../components/Header";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import Faq from "../components/Faq";
import Feed from "../components/Feed";

export const Home = () => {
  return (
    <div className="w-screen h-screen">
      <div className="h-[20%] w-full">
        <Header />
      </div>
      <div className="h-[75%] md:h-[50%] w-full">
        <Filters />
      </div>
      <div className="w-full">
        <Feed />
      </div>
      <div className="w-full">
        <Faq />
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
