import React, { useContext, useState } from "react";
import GoogleLogin from "../components/GoogleSignIn";
import { auth, sendPasswordResetEmail } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import CARTAGO_LOGO from "../assets/CARTAGO_LOGO.png";
import { useNavigate } from "react-router-dom";
import backgroundImg from "../assets/bg-image-cartago.png";
import { UserContext } from "../providers/UserContext";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const navigate = useNavigate();
  const { setUserToken } = useContext(UserContext);
  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const token = await result.user.getIdToken();
      setUserToken(token);
      navigate("/home");
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="flex">
      <div className="lg:w-[40%] md:w-0 h-screen">
        <div
          className="w-full h-full bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${backgroundImg})` }}
        ></div>
      </div>
      <div className="lg:w-[60%] md:w-screen h-screen">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src={CARTAGO_LOGO}
              alt="Cartago"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cartago-blue sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              {!resetPassword && (
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <span
                        className="font-semibold text-cartago-blue hover:text-indigo-500"
                        onClick={() => setResetPassword(true)}
                      >
                        Forgot password?
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cartago-blue sm:text-sm sm:leading-6"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div>
                {error && <p>{error}</p>}
                {!resetPassword && (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-cartago-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cartago-blue"
                  >
                    Sign in
                  </button>
                )}
                {resetPassword && (
                  <button
                    onClick={handleForgotPassword}
                    className="flex w-full justify-center rounded-md bg-cartago-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cartago-blue"
                  >
                    Reset Password
                  </button>
                )}
              </div>
            </form>
            <GoogleLogin /> {/* Add the GoogleLogin component */}
            <p className="mt-2 text-center text-sm text-gray-500">
              Not a member?{" "}
              <a
                href="/signup"
                className="font-semibold leading-6 text-cartago-blue hover:text-indigo-500"
              >
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
