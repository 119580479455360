export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAFpOGpvaxn3uzMmNi4kVxtUR8baCAlTZU",
    authDomain: "cartago-6b6d1.firebaseapp.com",
    databaseURL: "https://cartago-6b6d1-default-rtdb.firebaseio.com",
    projectId: "cartago-6b6d1",
    storageBucket: "cartago-6b6d1.appspot.com",
    messagingSenderId: "264537022886",
    appId: "1:264537022886:web:e483d5f68325f0c165af67",
    measurementId: "G-73BQ9YXYT9",
  },
  collections: {
    users: "users",
    byotSearchHistory: "byot_search_history",
    //TODO: change these to the correct collections
    planned_activities: "planned_activities_dev",
    planned_flights: "planned_flights_dev",
    planned_hotels: "planned_hotels_dev",
    trips: "trips",
  },
  stripePublicKey:
    "pk_test_51N8dEZIxdiM71gIjUk0Aak0nO60bDo4Oyflg6NuCJdujb0M6RSoHqGb1zlJJXnw9IJei9HFNc6jlW6vCF9w2BMH400l8DKxYC2", //this may change
};
