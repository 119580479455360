import axios from 'axios';
import { LocationsDatabaseHTTPRequest, CitiesDatabaseHTTPResponse, StatesDatabaseHTTPResponse, CountriesDatabaseHTTPResponse } from '../interfaces/locations_database_interfaces';
const apiBaseURL = 'https://us-central1-cartago-6b6d1.cloudfunctions.net/';
const apiClient = axios.create({
    baseURL: apiBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  


/**
 * Sends a POST request to the cloud function to get cities from database.
 * @async
 * @function
 * @param {LocationsDatabaseHTTPRequest} queryOptions - A locations request object containing the following attributes:
 *   - cityName: the city name to query
 *   - limit: the max number of results the query should return
 * @returns {Promise<CitiesDatabaseHTTPResponse>} A promise that resolves to the database response as a CitiesDatabaseHTTPResponse object.
 */
export async function getCitiesFromDatabase(queryOptions: LocationsDatabaseHTTPRequest): Promise<CitiesDatabaseHTTPResponse> {
    const response = await apiClient.post<CitiesDatabaseHTTPResponse>('/GetCitiesFromDatabase', queryOptions);
    return response.data;
}
/**
 * Sends a POST request to the cloud function to get cities in a country from database.
 * @async
 * @function
 * @param {LocationsDatabaseHTTPRequest} queryOptions - A locations request object containing the following attributes:
 *   - countryName: the country name to query
 *   - limit: the max number of results the query should return
 * @returns {Promise<CitiesDatabaseHTTPResponse>} A promise that resolves to the database response as a CitiesDatabaseHTTPResponse object.
 */
export async function getCitiesInCountryFromDatabase(queryOptions: LocationsDatabaseHTTPRequest): Promise<CitiesDatabaseHTTPResponse> {
    const response = await apiClient.post<CitiesDatabaseHTTPResponse>('/GetCitiesInCountryFromDatabase', queryOptions);
    return response.data;
}
/**
 * Sends a POST request to the cloud function to get states from database.
 * @async
 * @function
 * @param {LocationsDatabaseHTTPRequest} queryOptions - A locations request object containing the following attributes:
 *   - stateName: the state name (or code) to query
 *   - limit: the max number of results the query should return
 * @returns {Promise<StatesDatabaseHTTPResponse>} A promise that resolves to the database response as a StatesDatabaseHTTPResponse object.
 */
export async function getStatesFromDatabase(queryOptions: LocationsDatabaseHTTPRequest): Promise<StatesDatabaseHTTPResponse> {
    const response = await apiClient.post<StatesDatabaseHTTPResponse>('/GetStatesFromDatabase', queryOptions);
    return response.data;
}
/**
 * Sends a POST request to the cloud function to get countries from database.
 * @async
 * @function
 * @param {LocationsDatabaseHTTPRequest} queryOptions - A locations request object containing the following attributes:
 *   - countryName: the country name (or Iso3/Iso2 code) to query
 *   - limit: the max number of results the query should return
 * @returns {Promise<CountriesDatabaseHTTPResponse>} A promise that resolves to the database response as a CountriesDatabaseHTTPResponse object.
 */
export async function getCountriesFromDatabase(queryOptions: LocationsDatabaseHTTPRequest): Promise<CountriesDatabaseHTTPResponse> {
    const response = await apiClient.post<CountriesDatabaseHTTPResponse>('/GetCountriesFromDatabase', queryOptions);
    return response.data;
}