import React, { useState } from "react";
import { DatePicker, Input, Select, SelectItem } from "@nextui-org/react";
import { now, getLocalTimeZone } from "@internationalized/date";
import airlinesData from "../../assets/airlines.json";

export default function FlightForm() {
  const [flightClass, setFlightClass] = useState("");
  const [departureAirport, setDepartureAirport] = useState("");
  const [arrivalAirport, setArrivalAirport] = useState("");
  const [departureDate, setDepartureDate] = useState(now(getLocalTimeZone()));
  const [arrivalDate, setArrivalDate] = useState(now(getLocalTimeZone()));
  const [airline, setAirline] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [price, setPrice] = useState("");
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = {
      flightClass,
      departureAirport,
      arrivalAirport,
      departureDate,
      arrivalDate,
      airline,
      flightNumber,
      price,
    };
    console.log("Form Data:", formData);
    // Add form submission logic here (e.g., send data to a server)
  };

  return (
    <form className="w-full max-w-2xl" onSubmit={handleSubmit} id="flight-form">
      <div>
        <h2>Enter your flight details below.</h2>
      </div>
      <div className="grid gap-6 mt-5">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Select
              id="class"
              label={"Class"}
              labelPlacement="outside"
              placeholder="Select class"
              value={flightClass}
              onChange={(e) => setFlightClass(e.target.value)}
            >
              <SelectItem value="economy" key={"economy"}>
                Economy
              </SelectItem>
              <SelectItem value="business" key={"business"}>
                Business
              </SelectItem>
              <SelectItem value="first" key={"first"}>
                First
              </SelectItem>
            </Select>
          </div>
          <div className="space-y-2 flex flex-col">
            <Input
              id="departure-airport"
              placeholder="Enter departure airport"
              label={"Departure Airport"}
              labelPlacement="outside"
              value={departureAirport}
              onChange={(e) => setDepartureAirport(e.target.value)}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <Input
              id="arrival-airport"
              placeholder="Enter arrival airport"
              label={"Departure Airport"}
              labelPlacement="outside"
              value={arrivalAirport}
              onChange={(e) => setArrivalAirport(e.target.value)}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <Input
              id="flight-number"
              placeholder="Enter flight number"
              label={"Flight Number"}
              labelPlacement="outside"
              value={flightNumber}
              onChange={(e) => setFlightNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-[100%]">
            <DatePicker
              label={"Departure Date"}
              granularity="second"
              className="w-[100%]"
              description={"Departure Date"}
              labelPlacement={"outside"}
              hideTimeZone
              showMonthAndYearPickers
              value={departureDate}
              onChange={setDepartureDate}
            />
          </div>
        </div>
        <div>
          <div className="w-[100%]">
            <DatePicker
              label={"Arrival Date"}
              granularity="second"
              className="w-[100%]"
              description={"Arrival Date"}
              labelPlacement={"outside"}
              hideTimeZone
              showMonthAndYearPickers
              value={arrivalDate}
              onChange={setArrivalDate}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Select
              id="airline"
              label={"Airline"}
              labelPlacement="outside"
              placeholder="Select an airline"
              value={airline}
              onChange={(e) => setAirline(e.target.value)}
            >
              {airlinesData.map((airline: any) => {
                return (
                  <SelectItem value={airline.name} key={airline.code}>
                    {airline.name}
                  </SelectItem>
                );
              })}
            </Select>
          </div>
          <div className="space-y-2">
            <Input
              type="number"
              label="Price"
              placeholder="0.00"
              labelPlacement="outside"
              startContent={
                <div className="pointer-events-none flex items-center">
                  <span className="text-default-400 text-small">$</span>
                </div>
              }
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
